
@media (--breakpoint-md) {

  .md-p0  { padding:        0 }
  .md-pt0 { padding-top:    0 }
  .md-pr0 { padding-right:  0 }
  .md-pb0 { padding-bottom: 0 }
  .md-pl0 { padding-left:   0 }
  .md-px0 { padding-left:   0; padding-right:  0 }
  .md-py0 { padding-top:    0; padding-bottom: 0 }

  .md-p1  { padding:        var(--space-1) }
  .md-pt1 { padding-top:    var(--space-1) }
  .md-pr1 { padding-right:  var(--space-1) }
  .md-pb1 { padding-bottom: var(--space-1) }
  .md-pl1 { padding-left:   var(--space-1) }
  .md-px1 { padding-left:   var(--space-1); padding-right:  var(--space-1) }
  .md-py1 { padding-top:    var(--space-1); padding-bottom: var(--space-1) }

  .md-p2  { padding:        var(--space-2) }
  .md-pt2 { padding-top:    var(--space-2) }
  .md-pr2 { padding-right:  var(--space-2) }
  .md-pb2 { padding-bottom: var(--space-2) }
  .md-pl2 { padding-left:   var(--space-2) }
  .md-px2 { padding-left:   var(--space-2); padding-right:  var(--space-2) }
  .md-py2 { padding-top:    var(--space-2); padding-bottom: var(--space-2) }

  .md-p3  { padding:        var(--space-3) }
  .md-pt3 { padding-top:    var(--space-3) }
  .md-pr3 { padding-right:  var(--space-3) }
  .md-pb3 { padding-bottom: var(--space-3) }
  .md-pl3 { padding-left:   var(--space-3) }
  .md-px3 { padding-left:   var(--space-3); padding-right:  var(--space-3) }
  .md-py3 { padding-top:    var(--space-3); padding-bottom: var(--space-3) }

  .md-p4  { padding:        var(--space-4) }
  .md-pt4 { padding-top:    var(--space-4) }
  .md-pr4 { padding-right:  var(--space-4) }
  .md-pb4 { padding-bottom: var(--space-4) }
  .md-pl4 { padding-left:   var(--space-4) }
  .md-px4 { padding-left:   var(--space-4); padding-right:  var(--space-4) }
  .md-py4 { padding-top:    var(--space-4); padding-bottom: var(--space-4) }

}

