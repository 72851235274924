@import 'normalize.css';

/* Basscss Modules */
@import 'basscss-type-scale';
@import 'basscss-typography';
@import 'basscss-layout';
@import 'basscss-align';
@import 'basscss-margin';
@import 'basscss-padding';
@import 'basscss-grid';
@import 'basscss-flexbox';
@import 'basscss-position';
@import 'basscss-border';
@import 'basscss-hide';

@import 'basscss-addons/modules/responsive-margin';
@import 'basscss-addons/modules/responsive-padding';

$black: #2F3448;
$blue: #6699A6;
$white: #FAF4EF; /** equals rgba(250, 244, 239);*/
$orange: #F23827;
$red: #A60A0A;

body {
  /* background-color: $white; */
  background-image:url("./assets/icon-512x512.png");
  background-color:rgba(250, 244, 239, 0.8);
  background-blend-mode:lighten;
  color: $black;
  font-family:
    游ゴシック体, 'Yu Gothic', YuGothic, 'ヒラギノ角ゴシック Pro',
    'Hiragino Kaku Gothic Pro', メイリオ, Meiryo, Osaka,
    'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif;
}

.nav-link {
  position: relative;

  a {
    color: inherit;
    text-decoration: none;
  }
  .nav-link-bottom {
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 100%;
    border-bottom: solid $black 3px;
    transform: translateX(-50%);
    transition: 0.1s;
  }
  &:hover .nav-link-bottom {
    width: calc(100% + 1rem);
    transition: 0.1s;
  }
}

header {
  height: 28rem;
  background-color: $blue;

  .header-wrap {
    width: 100%;
  }
  h1 {
    line-height: 1.25em;
    font-size: 3rem;
  }
}

.scroll-bar-display-none{
  -ms-overflow-style: none !important;    /* IE, Edge 対応 */
  scrollbar-width: none !important;       /* Firefox 対応 */
}
.scroll-bar-display-none::-webkit-scrollbar {  /* Chrome, Safari 対応 */
  width: 0 !important;
}

main {
  line-height: 1.75em;
  h2 {
    line-height: 1.5em;
  }
}
